import * as React from 'react'

type Action =
  | {
      type: 'setMessage'
      message: string
      messageStatus: 'error' | 'success' | 'warning' | 'info' | undefined
    }
  | { type: 'resetMessage' }
type Dispatch = (action: Action) => void
type State = {
  messageStatus: 'error' | 'success' | 'warning' | 'info' | undefined
  message: string | undefined
}
type MessageProviderProps = { children: React.ReactNode }

const MessageContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined)

function messageReducer(state: State, action: Action) {
  switch (action.type) {
    case 'setMessage': {
      return {
        ...state,
        message: action.message,
        messageStatus: action.messageStatus,
      }
    }
    case 'resetMessage': {
      return { ...state, message: undefined, messageStatus: undefined }
    }
  }
}

function MessageProvider({ children }: MessageProviderProps) {
  const [state, dispatch] = React.useReducer(messageReducer, {
    message: undefined,
    messageStatus: undefined,
  })
  const value = { state, dispatch }
  return (
    <MessageContext.Provider value={value}>{children}</MessageContext.Provider>
  )
}

function useMessage() {
  const context = React.useContext(MessageContext)
  if (context === undefined) {
    throw new Error('useMessage must be used within a MessageProvider')
  }
  return context
}

export { MessageProvider, useMessage }
