import React, { memo } from 'react'
import {
  GlobalStyles as GlobalStylesBase,
  CssBaseline,
  useTheme,
} from '@mui/material'

const GlobalStyles = () => {
  const theme = useTheme()
  return (
    <>
      <CssBaseline />
      <GlobalStylesBase
        styles={{
          html: {
            fontSize: '62.5%;',
          },
        }}
      />
    </>
  )
}

export default memo(GlobalStyles)
