// npm
import React from 'react'
import { ThemeProvider } from '@mui/material'
import '@fontsource/lato'
import '@fontsource/playfair-display'
// src
import theme from './src/constants/theme-mui'
import { LoadVideoProvider } from './src/context/LoadVideoContext'
import { MessageProvider } from './src/context/MessageContext'
import { NavDrawerProvider } from './src/context/NavDrawerContext'
import GlobalStyles from './src/components/atoms/globalStyles/GlobalStyles'

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === 'undefined') {
    await import('intersection-observer')
  }

  if (!('scrollBehavior' in document.documentElement.style)) {
    const ss = await import('smoothscroll-polyfill')
    ss.polyfill()
  }
}

export const wrapRootElement = ({ element }) => {
  return (
    <MessageProvider>
      <NavDrawerProvider>
        <LoadVideoProvider>
          <ThemeProvider theme={theme}>
            <GlobalStyles />
            {element}
          </ThemeProvider>
        </LoadVideoProvider>
      </NavDrawerProvider>
    </MessageProvider>
  )
}
