import * as React from 'react'

type Action = {
  type: 'setLoadVideo'
}
type Dispatch = (action: Action) => void
type State = {
  loadVideo: boolean
}
type LoadVideoProviderProps = { children: React.ReactNode }

const LoadVideoContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined)

function navDrawerReducer(state: State, action: Action) {
  switch (action.type) {
    case 'setLoadVideo': {
      state.loadVideo = true
      return {
        ...state,
      }
    }
  }
}

function LoadVideoProvider({ children }: LoadVideoProviderProps) {
  const [state, dispatch] = React.useReducer(navDrawerReducer, {
    loadVideo: false,
  })
  const value = { state, dispatch }
  return (
    <LoadVideoContext.Provider value={value}>
      {children}
    </LoadVideoContext.Provider>
  )
}

function useLoadVideo() {
  const context = React.useContext(LoadVideoContext)
  if (context === undefined) {
    throw new Error('useLoadVideo must be used within a LoadVideoProvider')
  }
  return context
}

export { LoadVideoProvider, useLoadVideo }
