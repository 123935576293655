import { createTheme } from '@mui/material/styles'
import { alpha } from '@mui/system'

declare module '@mui/material/styles/createTypography' {
  interface Typography {
    backgroundText: React.CSSProperties
    quote: React.CSSProperties
    quotee: React.CSSProperties
    footerContact: React.CSSProperties
    bodyContact: React.CSSProperties
    heroText: React.CSSProperties
    leadText: React.CSSProperties
    cardHeading: React.CSSProperties
    fonts: {
      sans: string
      serif: string
    }
  }
  interface TypographyOptions {
    backgroundText?: React.CSSProperties
    quote?: React.CSSProperties
    quotee?: React.CSSProperties
    footerContact?: React.CSSProperties
    bodyContact?: React.CSSProperties
    heroText: React.CSSProperties
    leadText: React.CSSProperties
    cardHeading: React.CSSProperties
    fonts: {
      sans: string
      serif: string
    }
  }
}

declare module '@mui/material/Typography/Typography' {
  interface TypographyPropsVariantOverrides {
    backgroundText: true
    quote: true
    quotee: true
    footerContact: true
    bodyContact: true
    heroText: true
    leadText: true
    cardHeading: true
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    masonrySpacing: string
  }
  interface Palette {
    dividerDark: string
    textLight: StringConstructor
  }
  interface ThemeOptions {
    masonrySpacing: string
  }
  interface PaletteOptions {
    dividerDark?: string
    textLight?: string
  }
}

const fonts = {
  sans: "'Lato', sans-serif;",
  serif: "'Playfair Display', serif;",
}

const fontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
}

const colors = {
  primary: '#ca9e75',
  secondary: '#181d23',
  textPrimary: '#4a4a49',
  textSecondary: 'rgba(34,50,61,.6)',
  textLight: 'rgba(74,74,73,.7)', // textPrimary: '#4a4a49'
  dividerDark: '#4a4a4a',
}

const captonFontSize = '1.3rem'

const theme = createTheme({
  spacing: 10,
  masonrySpacing: '18px',
  palette: {
    mode: 'light',
    primary: {
      main: colors.primary,
      light: '#f9f0eb',
    },
    secondary: {
      main: colors.secondary,
    },
    text: {
      primary: colors.textPrimary,
      secondary: colors.textSecondary,
    },
    divider: 'rgba(39,35,35,.12)',
    dividerDark: colors.dividerDark,
    textLight: colors.textLight,
  },
  typography: {
    htmlFontSize: 10,
    fonts: {
      sans: fonts.sans,
      serif: fonts.serif,
    },
    fontFamily: fonts.sans,
    fontWeightRegular: fontWeights.regular,
    h1: {
      fontFamily: fonts.serif,
    },
    h2: {
      fontFamily: fonts.serif,
    },
    h3: {
      fontFamily: fonts.serif,
    },
    h4: {
      fontFamily: fonts.serif,
    },
    h5: {
      fontFamily: fonts.serif,
    },
    body1: {
      fontFamily: fonts.sans,
      fontWeight: fontWeights.regular,
    },
    body2: {
      fontFamily: fonts.sans,
      fontWeight: fontWeights.regular,
    },
    subtitle1: {
      fontFamily: fonts.sans,
      fontWeight: fontWeights.regular,
      textTransform: 'uppercase',
      letterSpacing: '0.3rem;',
    },
    subtitle2: {
      fontFamily: fonts.sans,
      fontWeight: fontWeights.regular,
      textTransform: 'uppercase',
      letterSpacing: '0.1rem;',
    },
    caption: {
      fontFamily: fonts.sans,
      letterSpacing: '0.2rem',
      fontWeight: fontWeights.light,
      fontSize: captonFontSize,
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
    },
    backgroundText: {
      fontFamily: fonts.serif,
      fontSize: '30rem',
      whiteSpace: 'nowrap',
      lineHeight: 1,
      opacity: 0.1,
    },
    heroText: {
      fontFamily: fonts.sans,
    },
    leadText: {
      fontFamily: fonts.serif,
      fontWeight: fontWeights.regular,
    },
    cardHeading: {
      fontFamily: fonts.serif,
      fontSize: '2.4rem',
      fontWeight: fontWeights.regular,
    },
    quote: {
      fontFamily: fonts.serif,
      fontStyle: 'italic',
      lineHeight: 1.5,
    },
    quotee: {
      fontFamily: fonts.sans,
      lineHeight: 1,
      letterSpacing: '0.2rem',
      fontSize: '1.3rem',
      fontWeight: fontWeights.light,
      textTransform: 'uppercase',
    },
    footerContact: {
      fontSize: '2.2rem',
      fontFamily: fonts.serif,
      lineHeight: 1.6,
      letterSpacing: '0.1rem',
    },
    bodyContact: {
      fontFamily: fonts.serif,
      fontWeight: fontWeights.regular,
      lineHeight: 1.6,
      letterSpacing: '0.1rem',
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '25px',
          paddingRight: '25px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          letterSpacing: '0.2rem',
          fontFamily: fonts.sans,
          fontWeight: fontWeights.regular,
          fontSize: '1.3rem',
          boxShadow: 'none',
          height: '60px',
          padding: '0 4rem',
          whiteSpace: 'nowrap',
        },
        outlined: {
          borderBottomWidth: '3px',
          '&:hover': {
            borderBottomWidth: '3px',
          },
        },
        outlinedPrimary: {
          '.MuiTouchRipple-root': {
            color: colors.primary,
          },
          '&:hover': {
            backgroundColor: alpha(colors.primary, 0.1),
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: 0,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '40px',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          ['&.primary-hover:hover']: {
            color: colors.primary,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
          letterSpacing: '0.2rem',
          fontSize: '1.4rem',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          textTransform: 'uppercase',
          letterSpacing: '0.2rem',
        },
        shrink: {
          transform: 'translate(0px, -25px) scale(0.85)',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: '4rem',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active':
            {
              WebkitBoxShadow: '0 0 0 300px white inset',
            },
        },
        notchedOutline: {
          borderRadius: '0 !important' as any,
          borderColor: colors.dividerDark,
          ['legend']: {
            visibility: 'hidden !important' as any,
            ['span']: {
              display: 'none !important' as any,
            },
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0,0,0,0.7)',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          backgroundColor: colors.primary,
          borderRadius: 0,
          fontSize: `1.4rem !important`,
          whiteSpace: 'normal !important',
          alignItems: 'center',
        },
        icon: {
          order: 2,
          marginRight: 0,
          marginLeft: '12px',
        },
        message: {
          order: 1,
        },
        filledError: {
          backgroundColor: colors.dividerDark,
        },
      },
    },
  },
})

// @ts-ignore
theme.components.MuiOutlinedInput.styleOverrides.notchedOutline = {
  // @ts-ignore
  ...theme.components.MuiOutlinedInput.styleOverrides.notchedOutline,
  transition: theme.transitions.create(['border-color']),
}
// @ts-ignore
theme.components.MuiLink.styleOverrides.root = {
  // @ts-ignore
  ...theme.components.MuiLink.styleOverrides.root,
  textDecoration: 'underline !important',
  textDecorationColor: `${alpha(theme.palette.text.primary, 0.2)} !important`,
  [theme.breakpoints.up('lg')]: {
    textDecoration: 'none !important',
  },
  transition: theme.transitions.create(['color']),
}

theme.typography.heroText = {
  ...theme.typography.heroText,
  fontSize: '1.8rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.9rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '2.1rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '2.2rem',
  },
}

theme.typography.body1 = {
  ...theme.typography.body1,
  fontSize: '1.7rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.72rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.74rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.76rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.8rem',
  },
}
theme.typography.body2 = {
  ...theme.typography.body2,
  fontSize: '1.8rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.85rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.9rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.95rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '2rem',
  },
}

theme.typography.h1 = {
  ...theme.typography.h1,
  fontSize: '5rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '8rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '9rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '10rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '11rem',
  },
}

theme.typography = {
  ...theme.typography,
  fontSize: 12,
  [theme.breakpoints.up('md')]: {
    fontSize: 14,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 16,
  },
}
theme.typography.h2 = {
  ...theme.typography.h2,
  fontSize: '4rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '6rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '7rem',
  },
}

theme.typography.h3 = {
  ...theme.typography.h3,
  fontSize: '3rem',
  // [theme.breakpoints.up('sm')]: {
  //   fontSize: '3.5rem',
  // },
  [theme.breakpoints.up('md')]: {
    fontSize: '3.5rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '4rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '5rem',
  },
}
theme.typography.h4 = {
  ...theme.typography.h4,
  fontSize: '3rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '3.4rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '3.6rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '3.8rem',
  },
}

theme.typography.h5 = {
  ...theme.typography.h5,
  fontSize: '2.7rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '2.8rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.9rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '3rem',
  },
}

theme.typography.leadText = {
  ...theme.typography.leadText,
  fontSize: '2.3rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '2.4rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.6rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '2.8rem',
  },
}
theme.typography.subtitle1 = {
  ...theme.typography.subtitle1,
  fontSize: '1.3rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.4rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.6rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.7rem',
  },
}
theme.typography.subtitle2 = {
  ...theme.typography.subtitle2,
  fontSize: '1.1rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.3rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.4rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.5rem',
  },
}
theme.typography.quote = {
  ...theme.typography.quote,
  fontSize: '2.4rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '2.6rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.8rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '3rem',
  },
}
theme.typography.bodyContact = {
  ...theme.typography.bodyContact,
  fontSize: '2.2rem',
  [theme.breakpoints.up('sm')]: {
    fontSize: '2.4rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.6rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '2.8rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '3rem',
  },
}

export default theme
